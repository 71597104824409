import React from 'react'

export function Logo() {
  return (
    <g style={{transform: "scale(0.9) translate(23px, 14px)", fill: "#ffffff"}}>
        <path d="m12.996 0.94141c0.46484 0.11328 1.0508 0.31641 1.3477 0.47266 0.64062 0.33203 1.2734 0.92188 1.5273 1.4258 0.15234 0.3125 0.17578 0.44922 0.17578 1.1797 0 0.77734-0.015625 0.85156-0.23438 1.3047-0.29688 0.61719-0.59766 1.0352-1.1719 1.6562-1.4727 1.5781-2.0859 2.8203-2.082 4.1992 0 0.78906 0.14062 1.3203 0.51562 2.0039 0.32812 0.58984 0.55469 0.86719 1.4062 1.75 1.3125 1.3594 1.8398 2.4336 1.8477 3.7695 0.007813 0.54688 0.007813 0.55078 0.14062 0.39453 0.66016-0.8125 0.85547-2.3008 0.45703-3.4492-0.10547-0.3125-0.37109-0.87891-0.58984-1.2539-0.53516-0.94141-0.65234-1.3281-0.64844-2.1094 0.007812-1 0.26172-1.5625 1.6836-3.6797 1.082-1.6094 1.3008-2.2031 1.2461-3.3711-0.089844-1.6953-1.0586-3.2617-2.4141-3.8906-0.83594-0.38672-2.1289-0.62109-3.3945-0.61328h-0.62109z"/>
        <path d="m19.539 4.6992c0.67578 0.37109 1.0898 0.77344 1.3516 1.2891 0.20703 0.41406 0.22656 0.50781 0.19922 0.97656-0.050782 0.97656-0.54297 1.668-1.8398 2.6367-0.73828 0.54297-1.1367 0.9375-1.5234 1.5156-0.79687 1.168-0.77734 2.582 0.035157 4.0391 0.72656 1.3086 0.88672 2.2383 0.60938 3.5-0.039063 0.21484-0.039063 0.21484 0.25-0.09375 0.34766-0.37109 0.54688-0.75 0.70703-1.375 0.14453-0.55078 0.10938-1.1016-0.125-2.0391-0.19922-0.80078-0.16016-1.4297 0.11719-2.0195 0.23438-0.50781 0.49219-0.82422 1.6836-2.0234 1.0078-1.0312 1.2656-1.3867 1.4414-2.0039 0.375-1.3672 0.10938-2.6641-0.71484-3.4727-0.46094-0.44141-0.91016-0.69922-1.7266-0.97656-0.83594-0.26953-1.0039-0.25391-0.46484 0.046875z"/>
        <path d="m13.141 5.4648c-0.14453 0.50781-0.54297 1-1.3086 1.6328-0.30469 0.25781-0.65625 0.60156-0.77344 0.77344-0.41406 0.60938-0.46094 1.5859-0.13672 2.7305l0.17188 0.60938 0.046875-0.64063c0.082031-1.2578 0.40625-1.8828 1.5078-2.9102 0.35547-0.33203 0.71094-0.72266 0.78516-0.85938 0.17969-0.33594 0.17578-1.0078-0.015625-1.4219l-0.15234-0.32422z"/>
        <path d="m9.7734 12.121c-0.61719 1-0.33594 1.6602 1.1211 2.6055 1.1133 0.73047 1.9141 1.4805 2.2031 2.0625 0.25391 0.50781 0.45703 1.3789 0.42188 1.8125-0.023437 0.26172-0.003906 0.28906 0.066407 0.17969 0.14062-0.17969 0.37891-0.83594 0.50781-1.3711 0.23438-0.98438-0.015625-1.9102-0.65234-2.4883-0.16797-0.15234-0.63281-0.46875-1.0352-0.70703-1.4258-0.8125-2.043-1.3945-2.293-2.1211l-0.11328-0.34375z"/>
        <path d="m20.922 12.586c-0.33984 0.17187-0.69922 0.53125-0.84375 0.84375-0.16797 0.35937-0.17578 0.81641-0.023437 1.5625 0.15234 0.74219 0.10937 1.7227-0.10156 2.2656-0.085937 0.22266-0.30469 0.61719-0.48047 0.88281-0.41406 0.625-0.22266 0.53125 0.625-0.30859 1.0703-1.043 1.2305-1.6406 0.875-3.2188-0.19922-0.85938-0.17969-1.4023 0.058594-1.8633 0.078125-0.15625 0.13672-0.28516 0.12891-0.28516-0.011718 0.007812-0.11719 0.058594-0.23828 0.12109z"/>
        <path d="m9.3125 19.625c-2.8516 0.097656-5.0977 0.20703-7.2031 0.35156-1.0977 0.082032-2.0234 0.14453-2.0547 0.14453-0.035156 0-0.054688 8.3477-0.054688 18.555v18.547l0.15625 0.30859c0.44141 0.91016 2.2031 1.6133 5.0469 2.0195 3.3555 0.48047 8.0391 0.69922 12.883 0.62109 8.2578-0.13672 12.84-0.83594 14.316-2.1836 0.35938-0.32031 0.48828-0.65625 0.48828-1.2422v-0.53125l1.0039-0.039063c1.6992-0.074219 2.918-0.36719 4.2344-1.0195 1.6836-0.83984 3.1914-2.3086 4.3242-4.2461 0.97656-1.6523 1.7578-3.9883 2.1172-6.3086 0.32031-2.0625 0.42969-5.2578 0.25781-7.4141-0.42578-5.2422-1.8555-8.8164-4.5703-11.344-1.8906-1.7695-3.8086-2.5781-6.3984-2.6914l-0.96875-0.046875v-2.9688l-0.48828-0.042969c-1.0469-0.09375-3.8711-0.26562-6.0586-0.37109-2.6445-0.12891-14.191-0.19531-17.031-0.097656zm25.797 7.4609c1.6914 0.54297 3.3281 2.1055 4.3672 4.168 1.2227 2.4258 1.7812 5.4922 1.6875 9.2656-0.10156 3.7852-0.87891 6.6914-2.4141 8.9492-0.85156 1.25-1.6133 1.9453-2.7344 2.4609-0.78125 0.36328-1.2969 0.48437-2.2656 0.51953l-0.85938 0.035156v-25.656l0.82031 0.039063c0.60547 0.027343 0.96484 0.082031 1.3984 0.21875z"/>
    </g>    
  )
}

export function Projects() {

  let height = 8;
  let width = 10;
  let spacing = 2;

  return (
    <g style={{transform: "translate(12px, 24px)", fill: "#ffffff"}}>
		    <rect x={0} y={0} width={width} height={height}/>
		    <rect x={width + spacing} y={0} width={width} height={height}/>
		    <rect x={width * 2 + spacing * 2} y={0} width={width} height={height}/>
		    <rect x={0} y={height + spacing} width={width} height={height}/>
		    <rect x={width + spacing} y={height + spacing} width={width} height={height}/>
		    <rect x={width * 2 + spacing * 2} y={height + spacing} width={width} height={height}/>
    </g>    
  )
}

export function CV() {
  return (
    <g style={{transform: "scale(0.25) translate(65px, 70px)", fill: "#ffffff"}}>
      <path d="m2 123c-1 0-2-1-2-2v-119c0-1.1 0.89-2 2-2h100c1 0 2 1 2 2v119c0 1-1 2-2.12 2zm97-4v-115.01h-95.01v114.9zm-53.74-80.88 3.48 10.24 1.75-6.08-0.86-0.94c-0.39-0.56-0.47-1.06-0.26-1.48 0.46-0.92 1.43-0.75 2.33-0.75 0.94 0 2.1-0.18 2.4 1 0.1 0.39-0.03 0.81-0.3 1.23l-0.86 0.94 1.75 6.08 3.15-10.24c2.28 2.05 9.01 2.46 11.51 3.85 0.79 0.44 1.51 1 2.08 1.76 1.78 2.35 1.77 2.77 2.08 5.72-0.13 1.35-0.9 2.13-2.41 2.25h-39.11c-1.51-0.12-2.28-0.89-2.41-2.25 0.3-2.95 0.3-3.37 2.08-5.72 0.58-0.76 1.29-1.32 2.08-1.76 2.52-1.39 9.25-1.81 11.52-3.85zm15.64-15.28 0.12-5.13c-0.15-2.05-0.82-3.6-1.91-4.77-2.65-2.87-7.61-3.61-11.35-2.26-0.63 0.23-1.23 0.52-1.77 0.86-1.54 0.98-2.79 2.41-3.29 4.19-0.12 0.42-0.2 0.84-0.24 1.26-0.08 1.8-0.03 3.95 0.1 5.64-0.16 0.06-0.3 0.14-0.44 0.22-0.29 0.19-0.5 0.45-0.64 0.75-0.13 0.29-0.19 0.62-0.18 0.98 0.02 0.78 0.39 1.74 1.09 2.79l1.95 3.09c0.82 1.3 1.67 2.62 2.79 3.64 1.15 1.05 2.56 1.77 4.42 1.77 2 0.01 3.46-0.73 4.65-1.85 1.15-1.08 2.02-2.48 2.87-3.89l2.22-3.65c0.01-0.02 0.02-0.04 0.03-0.05 0.6-1.35 0.73-2.29 0.42-2.9-0.17-0.33-0.45-0.56-0.84-0.69zm9.57 85.34c-1.09 0-1.98-0.89-1.98-2 0-1.1 0.88-2 1.98-2h16.68c1.09 0 1.98 0.89 1.98 2 0 1.1-0.88 2-1.98 2h-16.68zm-53.65-14.56c-1.09 0-1.98-0.89-1.98-2 0-1.1 0.88-2 1.98-2h70.5c1.09 0 1.98 0.89 1.98 2 0 1.1-0.88 2-1.98 2h-70.5zm0.32-14.56c-1.09 0-1.98-0.89-1.98-2 0-1.1 0.88-2 1.98-2h70c1.09 0 1.98 0.89 1.98 2 0 1.1-0.88 2-1.98 2h-70zm-0.32-14.56c-1.09 0-1.98-0.89-1.98-2 0-1.1 0.88-2 1.98-2h30.13c1.09 0 1.98 0.89 1.98 2 0 1.1-0.88 2-1.98 2h-30.13zm37.82 0c-1.09 0-1.98-0.89-1.98-2 0-1.1 0.88-2 1.98-2h32.5c1.09 0 1.98 0.89 1.98 2 0 1.1-0.88 2-1.98 2h-32.5z"/>
    </g>    
  )
}

export function GitHub() {
  return (
    <g style={{transform: "scale(0.07) translate(165px, 205px)", fill: "#ffffff"}}>
      <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/>
    </g>    
  )
}
